export const toDDMMYYYY = (date: Date | string) => {
  if (date === null || date === undefined) {
    return '';
  }
  let current;
  if (typeof date === 'string') {
    current = new Date(date);
  } else {
    current = date;
  }
  return current.toLocaleDateString('fr-FR', { year: 'numeric', month: '2-digit', day: '2-digit' });
};

export const actualYear = () => {
  return new Date().getFullYear();
};

export const copyright = () => {
  return `© Owwner ${actualYear()}`;
};
