import getConfig from 'next/config';
const { API_URL } = getConfig().publicRuntimeConfig;
import Cookies from 'js-cookie';
import api from '../api';
import { PriceHubbleSaleValuation } from '../models/priceHubbleSaleValuation';
import { CreateAccountDto } from '../models/create-account';
import { BusinessProviderProspect } from '../models/business-provider-prospect';
import Ticket from '../models/ticket';

export const uploadFile = async (file, type) => {
  const formData = new FormData();
  formData.append('file', file);
  formData.append('type', type);

  const res = await api.post('/upload/send', formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
  return res;
};

export const getFile = async (file) => {
  const token = Cookies.get('token');
  const res = await fetch(`${API_URL}/upload/get/${file}`, {
    method: 'GET',
    headers: {
      authorization: `Bearer ${token}`,
    },
  });
  const data = await res.json();
  return data;
};

export const getAllFiles = async () => {
  const res = await api.get('/upload/list');
  return res.data.body;
};

export const getMagicLink = async (email, impersonate) => {
  const body = {
    email,
  };
  if (impersonate) {
    body['impersonate'] = impersonate;
  }
  const resMagic = await fetch(`${API_URL}/auth/magic`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  });
  return await resMagic.json();
};

export const getProfile = async () => {
  const { data } = await api.get('/user/profile');
  return data.body;
};

export const getTickets = async (accommodationId: string): Promise<Ticket[]> => {
  const res = await api.get(`/ticket/accommodation/${accommodationId}`);
  if (res.data?.body && !res.data.body.error) {
    return res.data.body;
  } else {
    return [];
  }
};

export const getTicket = async (ticketId: string) => {
  const res = await api.get(`/ticket/${ticketId}`);
  return res.data;
};

export const getTicketQuotations = async (ticketId: string) => {
  const res = await api.get(`/ticket/${ticketId}/quotation`);
  return res.data;
};

export const getTicketInvoices = async (ticketId: string) => {
  const res = await api.get(`/ticket/${ticketId}/invoice`);
  return res.data;
};

export const updateUser = async (userId, data) => {
  const res = await api.post(`/update/user/${userId}`, data, {
    headers: { 'Content-Type': 'application/json' },
  });
  return res.data;
};

export const updateAccommodation = async (accommodationId, data) => {
  const res = await api.post(`/update/accommodation/${accommodationId}`, data, {
    headers: { 'Content-Type': 'application/json' },
  });
  return res.data;
};

export const patchAccommodation = async (accommodationId, data) => {
  const res = await api.patch(`/accommodation/${accommodationId}`, data);
  return res.data;
};

export const getAccommodation = async (accommodationId) => {
  const res = await api.get(`/accommodation/${accommodationId}`);
  if (res && res.data && res.data.body) {
    return res.data.body;
  } else {
    throw new Error();
  }
};

export const getReleveCompte = async (adhId, typeAdherent) => {
  const res = await api.get(`/vilogi/dataviz/releveCompte/${adhId}&typeAdh=${typeAdherent === 'LOCATAIRE' ? 'LOCATAIRE' : 'PROPRIETAIRE'}`);
  return res.data;
};

export const getLease = async (accommodationVilogiId) => {
  const res = await api.get(`/vilogi/contratLocation/${accommodationVilogiId}`);
  return res.data.body[0];
};

export const getLeases = async (accommodationVilogiId) => {
  const res = await api.get(`/vilogi/contratLocation/${accommodationVilogiId}`);
  return res.data.body;
};

export const getLeasesOfTenant = async (accommodationVilogiId) => {
  const res = await api.get(`/vilogi/tenant/contratLocation?idAdh=${accommodationVilogiId}`);
  return res.data.body;
};

export const getQuittances = async (vilogiTenantId) => {
  const res = await api.get(`/vilogi/quittances?vilogiTenantId=${vilogiTenantId}`);
  return res.data.body;
};

/**
 *
 * @param params
 *   epoque : 0, 1, 2 ,3, 4
 * @returns
 */
export const getEncadrementloyer = async ({ piece, meuble, lat, long, epoque }) => {
  const res = await api.post('/encadrementloyers/', {
    piece,
    meuble,
    lat,
    long,
    epoque,
  });

  const resultToKeep = {};
  // keep only useful data, especially because some other data are very big
  Object.keys(res.data.data).forEach((key) => {
    const item = res.data.data[key];
    resultToKeep[key] = {
      datasetid: item.datasetid,
      fields: {
        annee: item.fields.annee,
        epoque: item.fields.epoque,
        max: item.fields.max,
        min: item.fields.min,
        ref: item.fields.ref,
      },
    };
  });
  return resultToKeep;
};

export const VALUATION_TYPES = {
  SALE: 'sale',
  RENT: 'rent',
};

export const getPriceHubbleValuation = async ({
  lat,
  long,
  construction_period,
  living_space,
  house_type,
  construction_year,
  condition,
  landArea,
  furnished,
  valuation_type = VALUATION_TYPES.RENT,
}) => {
  let buildingYear: number;
  if (construction_year) {
    buildingYear = construction_year;
  } else {
    switch (construction_period) {
      case 0:
        buildingYear = 1930; // ne sait pas
        break;
      case 1:
        buildingYear = 1930; // avant 1946
        break;
      case 2:
        buildingYear = 1970; // entre 1946 et 1970
        break;
      case 3:
        buildingYear = 1990; // entre 1971 et 1990
        break;
      case 4:
        buildingYear = 2000; // apres 1990
        break;
    }
  }
  const pricehubbleParams = {
    lat: lat,
    long: long,
    buildingYear: buildingYear,
    livingArea: living_space,
    house_type,
    furnished,
  };
  if (house_type === 1) {
    pricehubbleParams['landArea'] = landArea;
  }
  if ([0, 1, 2].includes(condition)) {
    const priceHubbleCondition = condition === 0 ? 'renovation_needed' : condition === 1 ? 'well_maintained' : 'new_or_recently_renovated';
    pricehubbleParams['condition.bathrooms'] = priceHubbleCondition;
    pricehubbleParams['condition.kitchen'] = priceHubbleCondition;
    pricehubbleParams['condition.flooring'] = priceHubbleCondition;
    pricehubbleParams['condition.windows'] = priceHubbleCondition;
    pricehubbleParams['condition.masonry'] = priceHubbleCondition;
  }
  let res;
  if (valuation_type === VALUATION_TYPES.SALE) {
    res = await api.post(`/pricehubble/valuation/sale_valuation`, pricehubbleParams);
  } else {
    res = await api.post(`/pricehubble/valuation/property_value`, pricehubbleParams);
  }
  return res.data;
};

export const createProspect = async (data) => {
  const res = await api.post('/prospect/', data);
  return res.data;
};

export const owwnerContactPhoneNumber = '01 76 44 16 76';
export const owwnerContactPhoneNumberWithoutSpaces = owwnerContactPhoneNumber.replace(/\s+/g, '');

export const validateNewRentalFilesUpload = async () => {
  //return await api.put(
  //  `/accommodation/${accommodationId}/status/${ACCOMMODATION_STATUS.MANDATE_TO_BE_SIGNED_BY_OWWNER}`
  //);
  // TODO how to handle case where documents are uploaded but mandate not signed?
  return Promise.resolve({ status: 200 });
};

export const createAccommodationOnboardingStep2 = async (accommodationId, onboardingStep2Data) => {
  return await api.post(`/onboarding/${accommodationId}/part2`, onboardingStep2Data);
};

export const getDonneeBancaire = async (idAdh) => {
  return await api.get(`/vilogi/donneeBancaire/${idAdh}`);
};

export const getProposedTenantFiles = async (accommodationId) => {
  return await api.get(`/accommodation/${accommodationId}/proposedTenantFile`);
};

export const acceptProposedTenantFile = (proposedTenantFileId: string) => {
  return api.post(`/tenantFile/proposedTenantFile/${proposedTenantFileId}/accept`);
};

export const rejectProposedTenantFile = (proposedTenantFileId, rejectionReason) => {
  return api.post(`/tenantFile/proposedTenantFile/${proposedTenantFileId}/reject`, { rejectionReason });
};

export const fetchTenantFile = (tenantFileId) => {
  return api.get(`/tenantFile/${tenantFileId}`);
};

export const getPriceHubbleRentValuation = async (accommodationId) => {
  return await (
    await api.get(`/pricehubble/${accommodationId}/rentValuation`)
  ).data;
};

export const getPriceHubbleSaleValuation = async (accommodationId: string): Promise<PriceHubbleSaleValuation[]> => {
  const res = await api.get(`/pricehubble/${accommodationId}/saleValuation`);
  return res.data.data;
};

export const getVilogiAccommodationDetails = async (id) => {
  if (!id) {
    return null;
  }
  const res = await api.get(`/vilogi/accommodation/${id}`);
  if (res && res.data) {
    return res.data;
  } else {
    return null;
  }
};

export const getHistoriqueRevisionsLoyer = async (contratId) => {
  const res = await api.get(`/vilogi/historiqueRevisionsLoyer?idContrat=${contratId}`);
  if (res && res.data) {
    return res.data;
  } else {
    return null;
  }
};

export const getLocAdherentInfo = async (adherentId) => {
  const res = await api.get(`/vilogi/locAdherent/info?idAdh=${adherentId}`);
  if (res && res.data) {
    return res.data;
  } else {
    return null;
  }
};

export const getVilogiMandates = async (adhId) => {
  const res = await api.get(`/vilogi/mandatLocation?idAdh=${adhId}`);
  if (res && res.data) {
    return res.data;
  } else {
    return null;
  }
};

export const getRelances = async (adhId) => {
  const res = await api.get(`/vilogi/relances?idAdh=${adhId}`);
  if (res && res.data) {
    return res.data;
  } else {
    return null;
  }
};

export const getSupportHistoryForUser = async (userId) => {
  const res = await api.get(`/support/user/${userId}`);
  if (res && res.data) {
    return res.data;
  } else {
    return null;
  }
};

export const getLoan = async (accommodationId) => {
  const res = await api.get(`/analysis/accommodation/${accommodationId}/loan`);
  if (res && res.data) {
    return res.data;
  } else {
    return null;
  }
};

export const createLoan = async (accommodationId, loan) => {
  return await api.put(`/analysis/accommodation/${accommodationId}/loan`, loan);
};

export const getBailleur = async (accommodationId) => {
  return await api.get(`/vilogi/accommodation/${accommodationId}/bailleur`);
};

export const getBailleurData = async (bailleurId) => {
  const res = await api.get(`/vilogi/bailleur/${bailleurId}`);
  return res.data;
};

export const getVilogiAccommodationDetails2 = async (accommodationId) => {
  return await api.get(`/vilogi/accommodation/${accommodationId}/biens`);
};

export const getPartnerAccommodations = async (userId) => {
  const res = await api.get(`/accommodation/partner/user/${userId}`);
  if (res && res.data) {
    return res.data;
  } else {
    return null;
  }
};

export const getUbiflowContacts = async (accommodationId) => {
  return await api.get(`/ubiflow/contact/accommodation/${accommodationId}`);
};

export const getPricePredict = async (price, code) => {
  if (!price || !code) {
    return [];
  }
  const res = await api.get(`https://dataapi.owwner.com/futurePricesEstimations?currentPrice=${price}&code=${code}`);
  if (res && res.data) {
    return res.data;
  } else {
    return [];
  }
};

export const fetchNotifications = async () => {
  return await api.get(`/notifications`);
};

export const markNotificationsAsRead = async (notificationId) => {
  return await api.post(`/notifications/${notificationId}/read`);
};

export const markNotificationsAsUnread = async (notificationId) => {
  return await api.post(`/notifications/${notificationId}/unread`);
};

export const markNotificationsAsArchived = async (notificationId) => {
  return await api.post(`/notifications/${notificationId}/archive`);
};

export const getTenantFilesForAccommodation = async (accommodationId: string) => {
  return await api.get(`/tenantFile/accommodation/${accommodationId}`);
};

export const postSurvey = async (userId, formData) => {
  return await api.post(`/user/${userId}/survey`, formData);
};

export const postGenericForm = async (formData) => {
  return await api.post(`/form/`, formData);
};

export const fetchConversation = async (ticketId) => {
  return await api.get(`/support/ticket/${ticketId}/conversation`);
};

export const createSupportTicketMessage = async ({ conversationId, text, senderEmail, senderUserId }) => {
  return await api.post(`/support/conversation/${conversationId}/message`, {
    text: text,
    senderEmail: senderEmail,
    senderUserId: senderUserId,
  });
};

export const getValuationsPdfUrl = async (accommodationId) => {
  return await api.get(`/analysis/valuationsPdf/${accommodationId}`);
};

export const createAccount = async (createAccountDto: CreateAccountDto) => {
  return await api.post(`/auth/account`, createAccountDto);
};

export const createBusinessProviderProspect = async (prospect: BusinessProviderProspect) => {
  return await api.post(`/business-provider/prospect`, prospect);
};

export const getProspects = async () => {
  return await api.get(`/business-provider/prospect`);
};

export const putCashflowProject = async (data) => {
  return await api.put('/cashflow/project', data);
};

export const getCashflowProjects = async () => {
  return await api.get(`/cashflow/project`);
};

export const getCashflowProject = async (projectId: string) => {
  return await api.get(`/cashflow/project/${projectId}`);
};

export const uploadBPIdCard = async (file) => {
  const formData = new FormData();
  formData.append('file', file);

  const res = await api.post('/business-provider/document/idcard', formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
  return res;
};

export const uploadBPRib = async (file) => {
  const formData = new FormData();
  formData.append('file', file);

  const res = await api.post('/business-provider/document/rib', formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
  return res;
};

export const fetchBPIdCard = async () => {
  return await api.get('/business-provider/document/idcard');
};

export const fetchBPRib = async () => {
  return await api.get('/business-provider/document/rib');
};

export const uploadDocToDMS = async (file) => {
  const formData = new FormData();
  formData.append('file', file);

  const res = await api.post('/upload/send-to-dms', formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
  return res;
};

export const createChargeRegulationData = async ({ s3Key, type, data, accommodationsLinked, year }) => {
  return await api.post(`/accommodation/chargeregulation/create`, { s3Key, type, data, accommodationsLinked, year });
};

export const fetchChargeRegulationData = async (accommodationId) => {
  return await api.get(`/accommodation/${accommodationId}/chargeregulation`);
};

export const fetchBusinessProviderData = async (userId) => {
  return await api.get(`/business-provider/${userId}/businessProviderData`);
};

export const submitBusinessProviderData = async (userId, data) => {
  return await api.post(`/business-provider/${userId}/businessProviderData`, data);
};

export const fetchTicketCreator = async (ticketId) => {
  return await api.get(`/ticket/${ticketId}/creator`);
};

export const updatePassword = async (newPassword) => {
  return await api.post(`/auth/password/new`, { password: newPassword });
};
