import Head from '../components/Head';
import Login from '../layouts/Login';

const LoginPage = () => {
  return (
    <div>
      <Head title="Owwner - Connexion" />
      <Login></Login>
    </div>
  );
};

export default LoginPage;
