/** @jsxImportSource @emotion/react */
/* eslint-disable @next/next/no-img-element */
import { useState } from 'react';
import { useRouter } from 'next/router';

import { getMagicLink } from '../utils/api-routes';
import { Box, Button, CircularProgress, Divider, Link, Stack, Typography, styled, useMediaQuery } from '@mui/material';
import { defaultTheme } from '../utils/material-ui';
import RocketLaunchOutlinedIcon from '@mui/icons-material/RocketLaunchOutlined';
import Grid from '@mui/material/Unstable_Grid2';
import { COLOR_TOMATE } from '../components/Analysis/colors';
import { copyright } from '../utils/date';

const Login = () => {
  const [email, setEmail] = useState('');
  const [error, setError] = useState(null);
  const [emailSent, setEmailSent] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const isMobile = useMediaQuery(defaultTheme.breakpoints.between('xs', 'sm'));

  const router = useRouter();

  const handleInputChange = (e) => {
    setEmail(e.target.value);
    setError(null);
  };

  const onSubmit = async () => {
    // eslint-disable-next-line no-useless-escape
    const mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,4})+$/;
    if (!isLoading) {
      if (email.match(mailformat)) {
        setIsLoading(true);
        try {
          const impersonate = router.query['impersonate'];
          let res;
          const lowerCaseEmail = email.toLowerCase();
          if (impersonate) {
            res = await getMagicLink(lowerCaseEmail, impersonate);
          } else {
            res = await getMagicLink(lowerCaseEmail, null);
          }
          if (res.status >= 400) {
            setError('Une erreur est survenue, veuillez vérifier votre adresse email.');
            setIsLoading(false);
          } else {
            setEmailSent(true);
            setIsLoading(false);
          }
        } catch (e) {
          setError('Une erreur est survenue, veuillez réessayer');
          setIsLoading(false);
        }
      } else {
        setError('Adresse email non valide');
      }
    }
  };

  if (isMobile && !emailSent) {
    const StyledInputElement = styled('input')(
      () => `
        border-radius: 15px;
        border: 2px solid #00676B;
        height: 45px;
        text-align: center;
        font-size: 16px;
        margin-bottom: 14px;
        width: 100%;
        width: -moz-available;          /* WebKit-based browsers will ignore this. */
        width: -webkit-fill-available;  /* Mozilla-based browsers will ignore this. */
        width: fill-available;
      `
    );

    return (
      <Box sx={{ backgroundColor: '#F3F4F3', minHeight: '100vh', padding: '10px' }}>
        <Box sx={{ marginBottom: '100px' }}>
          <a href="https://www.owwner.com">
            <img src="/assets/images/owwner-logo-simple.svg" alt="" width="128px" />
          </a>
        </Box>
        <Box textAlign={'center'}>
          <Typography sx={{ color: '#00676B', fontSize: '64px', fontFamily: 'Quicksand', fontWeight: 700 }}>Connexion</Typography>
          <Typography sx={{ color: '#00676B', fontSize: '24px', marginBottom: '24px' }}>Accéder à mon compte</Typography>
          <Box>
            <StyledInputElement placeholder="Votre email" onChange={handleInputChange} autoFocus value={email} type="email" id="email" />
          </Box>
          <Button
            sx={{
              background: '#00676B',
              borderRadius: '13px',
              width: '100%',
              height: '49px',
              color: '#FFF',
              textTransform: 'none',
              fontSize: '16px',
              fontFamily: 'Quicksand',
              fontWeight: 700,
              marginBottom: '24px',
            }}
            onClick={onSubmit}
          >
            {isLoading ? <CircularProgress sx={{ color: 'white' }} size="1.5rem" /> : 'Me Connecter ✨'}
          </Button>
          {error && <Typography sx={{ marginBottom: '24px', color: COLOR_TOMATE }}>{error}</Typography>}
          <Typography sx={{ color: '#00676B', fontWeight: 'bold' }} marginBottom="12px">{`Pas encore client ?`}</Typography>
          <Box sx={{ margin: '0 48px 24px' }}>
            <a
              href="https://app.owwner.com/onboarding"
              style={{ color: '#00676B', fontSize: '14px', fontWeight: 'bold' }}
              target="_blank"
              rel="noreferrer"
            >{`Confier mon bien en gestion et intégrer l'univers Owwner`}</a>
          </Box>
          <Box sx={{ margin: '0 24px 24px' }}>
            <Divider sx={{ background: 'rgba(0, 0, 0, 0.35)' }} />
          </Box>
          <Typography sx={{ color: '#00676B', fontWeight: 'bold' }} marginBottom="12px">{`Intéressé par un partenariat ?`}</Typography>
          <Typography sx={{ color: '#00676B', fontSize: '12px' }} marginBottom="12px">{`Vous êtes CGP, courtier, professionnel de l'investissement...`}</Typography>
          <Link style={{ color: '#00676B', fontSize: '14px', fontWeight: 'bold' }} href="/create-account">
            {`Créer mon compte partenaire`} <RocketLaunchOutlinedIcon sx={{ fontSize: 14, marginLeft: '4px' }} />
          </Link>
          <Stack
            direction="column"
            sx={{
              marginTop: '172px',
            }}
          >
            <Typography sx={{ fontSize: '12px' }}>{copyright()}</Typography>
            <Button href="https://www.owwner.com/politique-de-confidentialite" target="_blank" rel="noreferrer" sx={{ color: '#000', textTransform: 'none', fontSize: '12px', marginBottom: '-6px' }}>
              Politique de confidentialité
            </Button>
            <Button href="https://www.owwner.com/mentions-legales" target="_blank" rel="noreferrer" sx={{ color: '#000', textTransform: 'none', fontSize: '12px' }}>
              Mentions légales
            </Button>
          </Stack>
        </Box>
      </Box>
    );
  }

  if (!isMobile && !emailSent) {
    const StyledInputElement = styled('input')(
      () => `
        border-radius: 15px;
        border: 2px solid #00676B;
        height: 45px;
        text-align: center;
        font-size: 16px;
        margin-bottom: 14px;
        width: 100%;
        width: -moz-available;          /* WebKit-based browsers will ignore this. */
        width: -webkit-fill-available;  /* Mozilla-based browsers will ignore this. */
        width: fill-available;
      `
    );
    return (
      <Box sx={{ backgroundColor: '#F3F4F3', minHeight: '100vh' }}>
        <Box sx={{ marginBottom: '100px', padding: '10px', position: 'absolute' }}>
          <a href="https://www.owwner.com">
            <img src="/assets/images/owwner-logo-simple.svg" alt="" width="128px" />
          </a>
        </Box>
        <Grid container>
          <Grid xs={0} lg={6}>
            <Box
              sx={{
                backgroundImage: `url('/assets/images/login-group23.svg')`,
                backgroundSize: 'contain',
                backgroundRepeat: 'no-repeat',
                backgroundPositionY: '0px',
                backgroundPositionX: '0px',
                minHeight: '99vh',
                padding: '48px 0',
              }}
            >
              <Box sx={{ maxWidth: '800px', margin: 'auto' }}>
                <img src="/assets/images/Screenconnexion.jpg" width="100%" alt="captures d'écran du site owwner" />
              </Box>
            </Box>
          </Grid>
          <Grid
            xs={12}
            lg={6}
            sx={{
              backgroundImage: `url('/assets/images/login-group3.svg')`,
              backgroundSize: '80%',
              backgroundRepeat: 'no-repeat',
              backgroundPositionY: '40px',
              backgroundPositionX: 'center',
              minHeight: '99vh',
            }}
          >
            <Box textAlign={'center'} sx={{ backgroundColor: '#FFF', width: '400px', margin: '96px auto 0', borderRadius: '55px', padding: '24px', border: '3px solid #00676B' }}>
              <Typography sx={{ color: '#00676B', fontSize: '64px', fontFamily: 'Quicksand', fontWeight: 700 }}>Connexion</Typography>
              <Typography sx={{ color: '#00676B', fontSize: '24px', marginBottom: '24px' }}>Accéder à mon compte</Typography>
              <Box>
                <StyledInputElement
                  placeholder="Votre email"
                  onChange={handleInputChange}
                  onKeyUp={(event) => {
                    if (event.key.toLowerCase() === 'enter') onSubmit();
                  }}
                  autoFocus
                  value={email}
                  type="email"
                  id="email"
                />
              </Box>
              <Button
                sx={{
                  background: '#00676B',
                  borderRadius: '13px',
                  width: '100%',
                  height: '49px',
                  color: '#FFF',
                  textTransform: 'none',
                  fontSize: '16px',
                  fontFamily: 'Quicksand',
                  fontWeight: 700,
                  marginBottom: '24px',
                  ':hover': {
                    background: '#00676B80',
                    color: '#FFF',
                  },
                }}
                onClick={onSubmit}
              >
                {isLoading ? <CircularProgress sx={{ color: 'white' }} size="1.5rem" /> : 'Me Connecter ✨'}
              </Button>
              {error && <Typography sx={{ marginBottom: '24px', color: COLOR_TOMATE }}>{error}</Typography>}
              <Typography sx={{ color: '#00676B' }} marginBottom="12px">{`Pas encore client ?`}</Typography>
              <Box sx={{ margin: '0 48px 24px' }}>
                <a
                  href="https://app.owwner.com/onboarding"
                  style={{ color: '#00676B', fontSize: '16px', fontWeight: 'bold' }}
                  target="_blank"
                  rel="noreferrer"
                >{`Confier mon bien en gestion et intégrer l'univers Owwner`}</a>
              </Box>
            </Box>
            <Box textAlign={'center'} sx={{ backgroundColor: '#FFF', width: '400px', margin: '96px auto', borderRadius: '45px', padding: '24px', border: '3px solid #00676B' }}>
              <Typography sx={{ color: '#00676B', fontWeight: 'bold', fontSize: '24px' }} marginBottom="12px">{`Intéressé par un partenariat ?`}</Typography>
              <Typography sx={{ color: '#00676B', fontSize: '16px' }} marginBottom="12px">{`Vous êtes CGP, courtier, professionnel de l'investissement...`}</Typography>
              <Link style={{ color: '#00676B', fontSize: '16px', fontWeight: 'bold' }} href="/create-account">
                {`Créer mon compte partenaire`} <RocketLaunchOutlinedIcon sx={{ fontSize: 14, marginLeft: '4px' }} />
              </Link>
            </Box>
          </Grid>
        </Grid>
        <Box
          sx={{
            height: '96px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            marginTop: '-96px',
          }}
        >
          <Stack direction="row" sx={{ justifyContent: 'center', alignItems: 'baseline', alignContent: 'center' }} gap={4}>
            <Typography sx={{ fontSize: '16px' }}>{copyright()}</Typography>
            <Button href="https://www.owwner.com/politique-de-confidentialite" target="_blank" rel="noreferrer" sx={{ color: '#000', textTransform: 'none', fontSize: '16px', marginBottom: '-6px' }}>
              Politique de confidentialité
            </Button>
            <Button href="https://www.owwner.com/mentions-legales" target="_blank" rel="noreferrer" sx={{ color: '#000', textTransform: 'none', fontSize: '16px' }}>
              Mentions légales
            </Button>
          </Stack>
        </Box>
      </Box>
    );
  }

  if (isMobile && emailSent) {
    return (
      <Box sx={{ backgroundColor: '#00676B', minHeight: '100vh', padding: '10px' }}>
        <Box sx={{ marginBottom: '100px' }}>
          <a href="https://www.owwner.com">
            <img src="/assets/images/logo-owwner-white.png" alt="" width="128px" />
          </a>
          <Box textAlign={'center'} sx={{ borderRadius: '15px', background: '#FFF', marginTop: '100px', padding: '32px' }}>
            <Typography sx={{ color: '#00676B', fontSize: '18px', fontFamily: 'Quicksand', fontWeight: 700, marginBottom: '48px' }}>Consultez votre messagerie électronique.</Typography>
            <Typography sx={{ color: '#00676B', fontSize: '14px', fontFamily: 'Quicksand', fontWeight: 700 }}>{`Nous avons envoyé un lien de connexion à l’adresse ${email}.`}</Typography>
            <Typography
              sx={{ color: '#00676B', fontSize: '14px', fontFamily: 'Quicksand', fontWeight: 700, marginBottom: '32px' }}
            >{`Veuillez cliquer dessus dans les minutes qui suivent afin d'accéder à votre espace client.`}</Typography>
            <Typography sx={{ color: '#00676B', fontSize: '12px', fontFamily: 'Quicksand', fontWeight: 700 }}>{`Vous n'avez rien reçu ? `}</Typography>
            <Typography
              sx={{ color: '#00676B', fontSize: '12px', fontFamily: 'Quicksand', fontWeight: 700, marginBottom: '32px' }}
            >{`Vérifiez vos courriers indésirables ou contactez notre support sur hello@owwner.com`}</Typography>
          </Box>
        </Box>
        <Stack
          direction="column"
          sx={{
            marginTop: '172px',
          }}
        >
          <Typography sx={{ fontSize: '12px', color: '#FFF', margin: 'auto' }}>{copyright()}</Typography>
        </Stack>
      </Box>
    );
  }

  if (!isMobile && emailSent) {
    return (
      <Box
        sx={{
          backgroundColor: '#00676B',
          padding: '10px',
          backgroundImage: `url('/assets/images/login-group3-transparent.svg')`,
          backgroundPositionX: '100%',
          backgroundRepeat: 'no-repeat',
          backgroundSize: '50%',
        }}
      >
        <Box sx={{ minHeight: '80vh', marginBottom: '200px' }}>
          <a href="https://www.owwner.com">
            <img src="/assets/images/logo-owwner-white.png" alt="" width="128px" />
          </a>
          <Box textAlign={'center'} sx={{ borderRadius: '15px', background: '#FFF', padding: '32px 72px', margin: '100px 96px' }}>
            <Typography sx={{ color: '#00676B', fontSize: '36px', fontFamily: 'Quicksand', fontWeight: 700, marginBottom: '48px' }}>Consultez votre messagerie électronique.</Typography>
            <Typography sx={{ color: '#00676B', fontSize: '18px', fontFamily: 'Quicksand', fontWeight: 700 }}>{`Nous avons envoyé un lien de connexion à l’adresse ${email}.`}</Typography>
            <Typography
              sx={{ color: '#00676B', fontSize: '18px', fontFamily: 'Quicksand', fontWeight: 700, marginBottom: '32px' }}
            >{`Veuillez cliquer dessus dans les minutes qui suivent afin d'accéder à votre espace client.`}</Typography>
            <Typography sx={{ color: '#00676B', fontSize: '14px', fontFamily: 'Quicksand', fontWeight: 700 }}>{`Vous n'avez rien reçu ? `}</Typography>
            <Typography
              sx={{ color: '#00676B', fontSize: '14px', fontFamily: 'Quicksand', fontWeight: 700, marginBottom: '32px' }}
            >{`Vérifiez vos courriers indésirables ou contactez notre support sur hello@owwner.com`}</Typography>
          </Box>
        </Box>
        <Box
          sx={{
            height: '96px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            marginTop: '-96px',
          }}
        >
          <Stack direction="row" sx={{ justifyContent: 'center', alignItems: 'baseline', alignContent: 'center' }} gap={4}>
            <Typography sx={{ fontSize: '16px', color: '#FFF' }}>{copyright()}</Typography>
            <Button href="https://www.owwner.com/politique-de-confidentialite" target="_blank" rel="noreferrer" sx={{ color: '#FFF', textTransform: 'none', fontSize: '16px', marginBottom: '-6px' }}>
              Politique de confidentialité
            </Button>
            <Button href="https://www.owwner.com/mentions-legales" target="_blank" rel="noreferrer" sx={{ color: '#FFF', textTransform: 'none', fontSize: '16px' }}>
              Mentions légales
            </Button>
          </Stack>
        </Box>
      </Box>
    );
  }
};

export default Login;
